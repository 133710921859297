<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Teacher Class Mapping
          <div class="card-header-actions">
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show" style="font-size:1rem;">
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group
                            id="input-group-1"
                            :label="labels.teacherName +':'"
                            label-for="input-1"
                            style="text-align:left"
                            >
                            <select class="form-control" v-model="form.teacherName">
                                <option value=''>Select Teacher</option>
                                <option v-for="(item,index) in teacher_list" :key="index" 
                                :value="item.teacher_id">{{item.teacher_name}}</option>
                            
                            </select>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group
                        id="input-group-1"
                        :label="labels.className +':'"
                        label-for="input-1"
                        style="text-align:left"
                        >
                            <select class="form-control" v-model="form.className">
                                <option value=''>Select Class</option>
                                <option v-for="(item,index) in class_list" :key="index" :value="item.class_id">
                                {{item.class_standard}}
                                </option>
                            </select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <b-form-group
                        id="input-group-1"
                        :label="labels.divisionName +':'"
                        label-for="input-1"
                        style="text-align:left"
                        >
                            <select class="form-control" v-model="form.divisionName">
                                <option value=''>Select Division</option>
                                <option v-for="(item,index) in division_list" :key="index" :value="item.division_id">
                                {{item.division}}
                                </option>
                            </select>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group
                        id="input-group-1"
                        :label="labels.subject +':'"
                        label-for="input-1"
                        style="text-align:left"
                        >
                            <select class="form-control" v-model="form.subject">
                                <option value=''>Select Subject</option>
                                <option v-for="(item,index) in subject_list" :key="index" :value="item.subject_id">
                                {{item.subject_name}}
                                </option>
                            </select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                <div class="col-md-6">
                        <b-form-group
                        id="input-group-1"
                        :label="labels.academicYear +':'"
                        label-for="input-1"
                        style="text-align:left"
                        >
                            <select class="form-control" v-model="form.academicYear">
                                <option value=''>Select Year</option>
                                <option v-for="(item,index) in year_list" :key="index" :value="item.ac_year_id">
                                {{item.ac_year_description}}
                                </option>
                            
                            </select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8"></div>
                    <div class="col-md-4">
                        <b-button type="submit" variant="warning" @click="map_data" class="mx=2">{{labels.submit}}</b-button>
                        <b-button type="reset" variant="light" class="mx-2">{{labels.reset}}</b-button>
                    </div>
                </div>
            </b-form>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
      subject_list:[],
      teacher_list:[],
      division_list:[],
      year_list:[],
      class_list:[],
      form: {
       class_id:"",
       userName: "",
        teacherName:"",
        className:"",
        divisionName:"",
        subject:"",
        academicYear:"",
        password: "",
        checked: []
      },
      labelsEnglish: {
        title: "Teacher-Class Mapping",
        teacherName: "Teacher",
        divisionName: "Division",
        className: "Class",
        submit: "Submit",
        subject: "Subject",
        academicYear:"Academic Year",
        reset: "Reset"
      },
      labels: {
        title: "Teacher-Class Mapping",
        teacherName: "Teacher Name",
        divisionName: "Division",
        className: "Class",
        submit: "Submit",
        academicYear:"Academic Year",
        subject:"Subject",
        reset: "Reset"
      },
      labelsMarathi: {
        title: "शाळा सूची",
        teacherName: "शिक्षक",
        divisionName: "विभागणी",
        className: "वर्ग",
        subject:"विषय",
        academicYear:"शैक्षणिक वर्ष",
        submit: "स्वीकारा",
        reset: "पुनर्स्थापित"
      },
      
      show: true,
      isCollapsed: true,
       items: [
         { techaer_name: 'Ms. Aruna Patil',class_standard:2,subject_id:'Math',division:'B',ac_year:2021 },
         { techaer_name: 'Ms. Vinit Patil',class_standard:2,subject_id:'English',division:'B',ac_year:2021 }
        ],
        fields: [
          { key: 'techaer_name', label: 'Teacher', sortable: true, class: 'class1'},
          { key: 'class_standard', label: 'Class', sortable: true, class: 'class2'},
          { key: 'subject_id', label: 'Subject', sortable: true, class: 'class3'},
          { key: 'division', label: 'Division', sortable: true, class: 'class3'},
          { key: 'ac_year', label: 'Year', sortable: true, class: 'class3'},
        //  { key: 'actions', label: 'Action' }
        ],
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn:[]

    };
  },
  methods:{
    map_data(){
      var data = {
          'class_id': this.form.className,
          'division_id': this.form.divisionName,
          'teacher_id': this.form.teacherName,
          'subject_id': this.form.subject_id,
          'ac_year_id': this.form.academicYear
      };
      var promise = apis.add_TeacherSubjectMappings(data).then( response =>{
        //  this.$fire({
        //     text: "Teacher Mapped Sucessfully",
        //     type: "",
        //     timer: 3000,
        //     size:"xs",
        //     width:350,
        //     heightAuto: 'false'
        //   });
         alert("Teacher Mapped");
      }).catch(error => {

      })
    },
    onSubmit(event) {
      event.preventDefault();
      this.$store.dispatch("login", {
        token: "abcd",
        user: this.form
      });
      this.$router.replace("/dashboard");
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.userName = "";
      this.form.password = "";
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    get_teacher(){
        var promise = apis.list_Teachers().then( response => {
            this.teacher_list = response.data;
        }).catch( error => {

        })
        },
    get_class(){
        var promise = apis.list_ClassMasters().then( response => {
            this.class_list = response.data;
        }).catch( error => {

        })
        },
    get_subject(){
        var promise = apis.list_Subjects().then( response => {
            this.subject_list = response.data;
        }).catch( error => {

        })
        },
    get_division(){
        var promise = apis.list_DivisionMasters().then( response => {
            this.division_list = response.data;
        }).catch( error => {

        })
        },
    get_year(){
        var promise = apis.list_AcademicYears().then( response => {
            this.year_list = response.data;
        }).catch( error => {

        })
        },
    onSubmit(event) {
        event.preventDefault();
        if(this.para == '' || this.para == undefined){
            var data = {
              'class_standard': parseInt(this.form.class_id)
            };
            console.log(data);
            var promise = apis.add_ClassMasters(data)
            .then( response => {
              this.$alert("Class Added Sucessfully");
              this.$router.go(-1);
            }).catch(error => {

            });
          
        }else{
            // edit API will be call
             var data = {
               'school_id': this.id,
                'school_name': this.form.schoolName,
                'address': this.form.schoolAddress,
                'contact_no': this.form.contact,
                'contact_person': this.form.contactPerson,
                'district_id': '00000000-0000-0000-0000-000000000000'
            };
            console.log(data);
            axios.put(base_url + 'api/Schools/'+this.id,data).then( response => {
              this.isLoading = false;
              
              this.$alert("School Updated Sucessfully");
              this.$router.go(-1);

            }).catch(error => {

            });
        }
           
    }
  },
  created(){
      this.get_teacher();
      this.get_class();
      this.get_division();
      this.get_subject();
      this.get_year();
  }
};
</script>
